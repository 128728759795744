'use strict';

/****************************************************************
 * Name: CustomCursor
 * Author: Brad Barton (brad@qualifiedigital.com)
 * Contributors:
 * 	- Brad Barton (brad@qualifiedigital.com)
 * Purpose: Allow customized cursors to be created using html/css
 ****************************************************************/

import { merge } from 'lodash-es';

const CustomCursor = function (elem = null, options = {}) {
	if (!elem) throw new Error('Element undefined');

	let interval = null;

	const defaults = Object.freeze({
		cursorSelector: elem.getAttribute('data-custom-cursor'),
		activeClass: 'cursor--active',
		debounceTimer: 1
	});

	const settings = merge({}, defaults, options);

	const cursorElem = settings.cursorSelector ? document.querySelector(settings.cursorSelector) : elem.querySelector('[data-cursor]');

	const getMousePosition = (evt) => {
		if (!evt) throw new Error('getMousePosition() Error: event undefined');
		return {
			x: evt.clientX,
			y: evt.clientY
		};
	};

	const positionCursor = (evt = null) => {
		if (!evt) throw new Error('positionCursor() Error: event undefined');
		const position = getMousePosition(evt);
		cursorElem.style.top = position.y + 'px';
		cursorElem.style.left = position.x + 'px';
	};

	// hide the cursor onMouseLeave of the target elem
	const handleMouseLeave = (evt) => {
		cursorElem.classList.remove(settings.activeClass);
	};

	// show and position the cursor when the mouse is within the target elem
	const handleHover = (evt) => {
		if (!interval) {
			// timeout is used to keep the callstack from getting out of control
			// basically debouncing the event
			interval = setTimeout(() => {
				positionCursor(evt);
				interval = null;
			}, settings.debounceTimer);
		}

		cursorElem.classList.add(settings.activeClass);
	};

	const destroy = () => {
		elem.removeEventListener('mouseleave', handleMouseLeave);
		elem.removeEventListener('mousemove', handleHover);
		elem.removeEventListener('mouseenter', handleHover);
		delete elem.CustomCursor;
	};

	elem.addEventListener('mouseleave', handleMouseLeave);
	elem.addEventListener('mousemove', handleHover);
	elem.addEventListener('mouseenter', handleHover);

	// public api
	const api = {
		destroy
	};

	elem.CustomCursor = api;

	return api;
};

// ***************************************************
// initialize the plugin on all custom cursor elements
// ***************************************************
const customCursorContainers = document.querySelectorAll('[data-custom-cursor]');
const customCursorContainersArr = [...customCursorContainers];
customCursorContainersArr.forEach(elem => new CustomCursor(elem));

export default CustomCursor;
