'use strict';

import { merge } from 'lodash-es';
import Glide from '@glidejs/glide';

const Carousel = function (elem, options = {}) {
	try {
		if (!elem) throw new Error('Carousel element undefined');

		const defaults = {
			type: 'carousel', // Type of the movement
			startAt: 0, // Start at specific slide number
			perView: 1, //A number of visible slides
			focusAt: 'center', // Focus currently active slide at a specified position
			gap: 0, // A size of the space between slides
			autoplay: false, // Change slides after a specified interval
			hoverpause: true, // Stop autoplay on mouseover
			keyboard: true, // Change slides with keyboard arrows
			bound: false, // Stop running perView number of slides from the end
			swipeThreshold: 80, // Minimal swipe distance needed to change the slide
			dragThreshold: 120, // Minimal mousedrag distance needed to change the slide
			perTouch: false, // A maximum number of slides moved per single swipe or drag
			touchRatio: 1, // Alternate moving distance ratio of swiping and dragging
			touchAngle: 45, // Angle required to activate slides moving
			animationDuration: 600, // Duration of the animation
			rewind: true, // Allow looping the slider type
			rewindDuration: 800, // Duration of the rewinding animation
			animationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)', // Easing function for the animation
			direction: 'ltr', // Moving direction mode
			peek: 0, // The value of the future viewports which have to be visible in the current view
			breakpoints: {}, // Collection of options applied at specified media breakpoints
			classes: { // Collection of used HTML classes
				direction: {
					ltr: 'glide--ltr',
					rtl: 'glide--rtl'
				},
				slider: 'glide--slider',
				carousel: 'glide--carousel',
				swipeable: 'glide--swipeable',
				dragging: 'glide--dragging',
				slide: 'glide__slide',
				cloneSlide: 'glide__slide--clone',
				activeNav: 'glide__bullet--active',
				activeSlide: 'glide__slide--active',
				disabledArrow: 'glide__arrow--disabled'
			},
			throttle: 25 // Throttle costly events
		};

		const settings = merge({}, defaults, options);
		const slides = [...elem.querySelectorAll(`.${settings.classes.slide}`)];
		const glide = new Glide(elem, settings);

		let nextSlide = null;
		let nextClones = null;
		let previousSlide = null;
		let previousClones = null;

		const getActiveSlide = () => {
			return slides[glide.index];
		};

		const getNextSlide = (moveDirection) => {
			switch (moveDirection) {
				case '<': // previous
					if (glide.index === 0) return slides[slides.length - 1];
					return slides[glide.index - 1];
				case '>': // next
					if (glide.index === slides.length - 1) return slides[0];
					return slides[glide.index + 1];
				case '<<': // start
					return slides[0];
				case '>>': // end
					return slides[slides.length - 1];
				default: // number
					const moveToIndex = parseInt(moveDirection.slice(0, 1));
					return slides[moveToIndex];
			}
		};

		const getClonedSlidesByIndex = (index) => {
			return [...elem.querySelectorAll(`.glide__slide[data-index="${index}"]`)];
		};

		glide.on('run.before', (move) => {
			previousSlide = getActiveSlide();
			previousClones = getClonedSlidesByIndex(previousSlide.getAttribute('data-index'));
			nextSlide = getNextSlide(move.direction);
			nextClones = getClonedSlidesByIndex(nextSlide.getAttribute('data-index'));
		});

		glide.on('run', (move) => {
			[previousSlide, ...previousClones].forEach(slide => {
				slide.classList.remove(settings.classes.activeSlide);
			});

			[nextSlide, ...nextClones].forEach(slide => {
				slide.classList.add(settings.classes.activeSlide);
			});
		});

		glide.on('run.after', (move) => {
			previousSlide = null;
			nextSlide = null;
		});

		// glide.on('run.offset', (move) => console.log('run.offset', move));
		// glide.on('run.start', (move) => console.log('run.start', move));
		// glide.on('run.end', (move) => console.log('run.end', move));

		glide.mount();

		// public api
		return {
			glide: glide
		};
	} catch (err) {
		console.error(err);
	}
};

export default Carousel;
