'use strict';

import { merge } from 'lodash-es';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Animator from '../Animator/Animator.js';

gsap.registerPlugin(ScrollTrigger);

const Director = (elem = null, options = {}) => {
	try {
		if (!elem) throw new Error('Director error: elem undefined');

		const defaults = {
			timelineConfig: (() => {
				try {
					const o = JSON.parse(elem.getAttribute('data-timeline-config'));
					if (o && typeof o === 'object') return o;
				} catch (err) {}
				return {};
			})(),
			animatorSelector: '[data-animator]'
		};

		const settings = merge({}, defaults, options);

		const animators = [...elem.querySelectorAll(settings.animatorSelector)];

		const timeline = gsap.timeline(settings.timelineConfig);

		animators.forEach((el, i) => {
			const insertionPoint = el.getAttribute('data-insertion-point') || '>';

			timeline
				.add(Animator(el), insertionPoint)
				.addLabel(`Animator-${i}`);
		});

		return timeline;
	} catch (err) {
		console.error(err);
	}
};

export default Director;
