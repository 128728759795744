const imageScale = document.querySelector('.image-scale')

if (imageScale) {
	const imageScaleObserver = new IntersectionObserver(function(entries) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				entry.target.classList.add('animate')
				imageScaleObserver.unobserve(imageScale)
			}
		})
	}, {
		rootMargin: '0% 0% -25%'
	})

	imageScaleObserver.observe(imageScale)
}