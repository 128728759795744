'use strict';

import { merge } from 'lodash-es';
import gsap from 'gsap';

const Shifter = (elem = null, options = {}) => {
	try {
		if (!elem) throw new Error('Shifter Error: elem undefined');

		const defaults = {
			duration: 2,
			ease: 'power1.in',
			startX: 0,
			startY: 0,
			endX: 0,
			endY: 0
		};

		const settings = merge({}, defaults, options);

		const tween = gsap.fromTo(elem, {
			x: settings.startX,
			y: settings.startY
		}, {
			duration: settings.duration,
			ease: settings.ease,
			x: settings.endX,
			y: settings.endY
		});

		return tween;
	} catch (err) {
		console.error(err);
	}
};

export default Shifter;
