'use strict';

import { merge } from 'lodash-es';

const ShowError = function (elem, options = {}) {
  const myMessage = window.location.search;
  console.log('myMessage: ', myMessage);

  if (myMessage.includes('error')) {
    alert("Something went wrong. Please ensure you’ve provided all required fields and checked the reCAPTCHA box before submitting. We look forward to hearing from you.");
    window.location.replace("/contact.html");
  } else {
    console.log('no error');
  }


};


ShowError();
// export default ShowError;
