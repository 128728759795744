'use strict';

import MicroModal from 'micromodal';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Fader from '../Fader/Fader.js';
import Shifter from '../Shifter/Shifter.js';

gsap.registerPlugin(ScrollTrigger);

class GlobalHeader {
	elem = null;
	triggerElems = [];
	navContainerElem = null;
	navItemElems = [];
	state = 'closed';
	settings = {};

	navTimeline = null;

	constructor(elem = null, options = {}) {
		try {
			if (!elem)
				throw new Error('Navigation initialization error: element undefined');
			const defaults = Object.freeze({
				openClass: 'menu-is-open',
				buttonSelector: '.menu-button',
				navContainerSelector: '.global-header__nav-menu',
				navSelector: '.site-nav',
				navMenuSelector: '.site-nav__menu',
				navItemSelector: '.site-nav__item',
			});
			this.elem = elem;
			this.settings = { ...defaults, ...options };
			this.navContainerElem = this.elem.querySelector(
				this.settings.navContainerSelector
			);
			this.navItemElems = [
				...this.elem.querySelectorAll(this.settings.navItemSelector),
			];
			this.triggerElems = [
				...this.elem.querySelectorAll(this.settings.buttonSelector),
			];
			if (document.querySelector('.homepage-banner')) {
				setTimeout(() => {
					this.init();
				}, 200);
			} else {
				this.init();
			}
		} catch (err) {
			console.error(err);
		}
	}

	createNavTimeline() {
		this.navTimeline = gsap
			.timeline({
				paused: true,
				onStart: () => {
					setTimeout(() => {
						// need to wait one tick before adding classes so that gsap can correctly retrieve the header height
						this.navContainerElem.classList.add(this.settings.openClass);
						this.navContainerElem.setAttribute('aria-expanded', 'true');
						this.elem.classList.add(this.settings.openClass);
						this.triggerElems.forEach((elem) => {
							elem.classList.add(this.settings.openClass);
							elem.setAttribute('aria-label', 'Close Navigation Menu');
						});
					});
				},
				onComplete: () => {
					this.state = 'open';
					MicroModal.show(this.navContainerElem.id, {
						disableFocus: true,
						disableScroll: true,
					}); // use micromodal for accessability
				},
				onReverseComplete: () => {
					this.state = 'closed';
					this.navContainerElem.classList.remove(this.settings.openClass);
					this.navContainerElem.setAttribute('aria-expanded', 'false');
					this.elem.classList.remove(this.settings.openClass);
					this.triggerElems.forEach((elem) => {
						elem.classList.remove(this.settings.openClass);
						elem.setAttribute('aria-label', 'Open Navigation Menu');
					});
					MicroModal.close(this.navContainerElem.id); // use micromodal for accessability
				},
			})
			.addLabel('closed');

		this.navTimeline
			.to(
				this.elem,
				{
					duration: 0.5,
					height: '100vh',
				},
				0
			)
			.addLabel('opened');

		this.navItemElems.forEach((item, index) => {
			this.navTimeline
				.add(
					new Fader(item, {
						duration: 0.3,
					}),
					index === 0 ? '>' : '<0.1'
				)
				.add(
					new Shifter(item, {
						duration: 0.3,
						startY: '100%',
						endY: 0,
					}),
					'<'
				)
				.addLabel(`item-${index}`);
		});
	}

	openMenu(evt) {
		this.state = 'opening';
		this.navTimeline.play();
	}

	closeMenu(evt) {
		this.state = 'closing';
		this.navTimeline.reverse();
	}

	toggleMenu(evt) {
		if (this.state === 'open' || this.state === 'opening') {
			this.closeMenu(evt);
		} else {
			this.openMenu(evt);
		}
	}

	init() {
		this.createNavTimeline();
		this.triggerElems.forEach((elem) =>
			elem.addEventListener('click', (evt) => this.toggleMenu(evt))
		);

		const collapseTimeline = gsap.timeline({ paused: true });

		collapseTimeline.to(this.elem, {
			y: '-100%',
			duration: 0.5,
		});

		let prevProgress = -1;
		const scrollTrigger = ScrollTrigger.create({
			trigger: 'body',
			onUpdate: (self) => {
				if (
					prevProgress !== -1 &&
					!(this.state === 'opening' || this.state === 'open')
				) {
					// ignore if in initial positioning or menu is open
					if (self.progress > prevProgress) {
						// scroll down
						collapseTimeline.play();
					} else {
						// scroll up
						collapseTimeline.reverse();
					}
				}

				prevProgress = self.progress;
			},
		});
	}
}

const globalHeader = new GlobalHeader(
	document.querySelector('[data-module="GlobalHeader"]')
);
