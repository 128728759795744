'use strict';

import { merge } from 'lodash-es';
import gsap from 'gsap';

const Fader = (elem = null, options = {}) => {
	try {
		if (!elem) throw new Error('Fader error: elem is undefined');

		const defaults = {
			duration: 2,
			ease: 'power1.in',
			fadeOut: false
		};

		const settings = merge({}, defaults, options);

		const tween = gsap.fromTo(elem, {
			opacity: 0
		},{
			duration: settings.duration,
			ease: settings.ease,
			opacity: 1,
			runBackwards: settings.fadeOut,
		});

		return tween;
	} catch (err) {
		console.error(err);
	}
};

export default Fader;
