'use strict';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

if (document.querySelector('.services')) {
	const services = document.querySelector('.services');
	const servicesSlides = document.querySelector('.services-slides');
	const slides = servicesSlides.querySelectorAll('.slide');
	const slideLength = slides.length;
	const updateClasses = (current, next) => {
		const currentSlide = direction == 'down' ? slides[current] : slides[next];
		const nextSlide = direction == 'down' ? slides[next] : slides[current];

		currentSlide.classList.remove('active-slide');
		nextSlide.classList.add('active-slide');
	};
	const fadeOutDelay = '-=95%';
	const fadeInDelay = '-=25%';
	let direction = 'down';

	setTimeout(() => {
		const t1 = gsap.timeline({
			scrollTrigger: {
				trigger: services,
				start: 'top top',
				end: () => '+=' + servicesSlides.offsetHeight * slideLength,
				pin: true,
				scrub: 1,
				invalidateOnRefresh: true,
				onUpdate: self => direction = self.direction == 1 ? 'down' : 'up'
			}
		});
		for (let i = 0; i < slideLength - 1; i++) {
			t1.fromTo(document.querySelector(`.background-${i + 1}`), {
				opacity: 0,
				y: '100%'
			}, {
				delay: .5,
				duration: 2,
				opacity: 1,
				y: '0%'
			})
			.fromTo(slides[i].querySelector('.cta'), {
				opacity: 1
			},{
				opacity: 0
			}, '<')
			.fromTo(slides[i].querySelector('cite'), {
				opacity: 1
			},{
				opacity: 0
			}, fadeOutDelay)
			.fromTo(slides[i].querySelector('p'), {
				opacity: 1
			}, {
				opacity: 0
			}, fadeOutDelay)
			.fromTo(slides[i].querySelector('.title-large'), {
				opacity: 1
			}, {
				opacity: 0
			}, fadeOutDelay)
			.fromTo(slides[i].querySelector('.services-eyebrow'), {
				opacity: 1
			}, {
				opacity: 0
			}, fadeOutDelay)
			.fromTo(slides[i].querySelector('.slide-image'), {
				opacity: 1
			}, {
				opacity: 0
			}, '<')
			.fromTo(slides[i + 1].querySelector('.slide-image'), {
				opacity: 0
			}, {
				opacity: 1
			}, '<')
			.call(() => updateClasses(i, i + 1))
			.fromTo(slides[i + 1].querySelector('.services-eyebrow'), {
				y: -10,
				opacity: 0
			}, {
				y: 0,
				opacity: 1
			}, fadeInDelay)
			.fromTo(slides[i + 1].querySelector('.title-large'), {
				y: -10,
				opacity: 0
			}, {
				y: 0,
				opacity: 1
			}, fadeInDelay)
			.fromTo(slides[i + 1].querySelector('p'), {
				y: -20,
				opacity: 0
			}, {
				y: 0,
				opacity: 1
			}, fadeInDelay)
			.fromTo(slides[i + 1].querySelector('cite'), {
				y: -10,
				opacity: 0
			}, {
				y: 0,
				opacity: 1
			}, fadeInDelay)
			.fromTo(slides[i + 1].querySelector('.cta'), {
				y: -10,
				opacity: 0
			}, {
				y: 0,
				opacity: 1
			}, fadeInDelay);
		}
	}, 100);
}
