const caseStudyStats = document.querySelector('.case-study-stats')

if (caseStudyStats) {
	const statsObserver = new IntersectionObserver(function(entries) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				entry.target.classList.add('animate')
				statsObserver.unobserve(caseStudyStats)
			}
		})
	}, {
		rootMargin: '0% 0% -25%'
	})

	statsObserver.observe(caseStudyStats)
}