'use strict';

import CustomCursor from '../CustomCursor/CustomCursor.js';

const Guide = () => {
	let initialized = false;
	const guide = document.createElement('div');
	guide.className = "cursor guide";

	const img = new Image();
	img.src = '/images/ee/knuckles.png';

	guide.appendChild(img);

	const audio = {
		follow: new Audio('/images/ee/follow-me.mp3'),
		lost: new Audio('/images/ee/you-do-not-know-the-way.mp3')
	};

	const handleMouseEnter = (evt) => {
		audio.follow.play();
	};

	const handleClick = (evt) => {
		if (!initialized) {
			document.body.appendChild(guide);

			const links = [...document.querySelectorAll('a')];

			links.forEach((link) => {
				if (link.CustomCursor) link.CustomCursor.destroy();
				link.addEventListener('mouseenter', handleMouseEnter);
				new CustomCursor(link, {cursorSelector: '.cursor.guide'});
			});
		}

		initialized = true;
		audio.lost.play();
	};

	const lostBtn = document.querySelector('.i-am-lost');
	lostBtn.addEventListener('click', handleClick);
};

Guide();
