window.addEventListener("load", function () {
    const form = document.getElementById('summit-rsvp-form');
    const ty = document.getElementById('form-thank-you');
    const submitBtn = document.getElementById('submit-button');

    form.addEventListener("submit", function (e) {
        e.preventDefault();

        submitBtn.setAttribute("disabled", true);
        submitBtn.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <strong>Submitting...</strong>`;

        const data = new FormData(form);
        const action = e.target.action;

        fetch(action, {
            method: 'POST',
            body: data
        }).then(() => {
            ty.classList.remove('hide');
            form.classList.add('hide');
        })
    });
});