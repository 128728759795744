'use strict';

import Carousel from './Carousel';

try {
	const defaults = {
		perView: 4,
		autoplay: true,
		hoverpause: true,
		keyboard: false,
		swipeThreshold: false,
		dragThreshold: false,
		animationDuration: 6000,
		animationTimingFunc: 'cubic-bezier(0, 0, 1, 1)',
		peek: 0,
		gap: 25,
		breakpoints: {
			800: {
				perView: 3
			},
			600: {
				perView: 2
			},
			400: {
				perView: 1
			}
		}
	};

	const carousels = document.querySelectorAll('[data-module="MarqueeCarousel"]');
	[...carousels].forEach(carousel => {
		const settings = {
			...defaults,
			direction: carousel.getAttribute('data-direction') || 'ltr'
		};
		return new Carousel(carousel, settings);
	});
} catch (err) {
	console.log(err);
}
