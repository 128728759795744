'use strict';

import { merge } from 'lodash-es';
import gsap from 'gsap';
import TextPlugin from 'gsap/TextPlugin';

gsap.registerPlugin(TextPlugin);

const checkComplete = () => {
	if (document.querySelector('.hero-banner')) {
		document.querySelector('.hero-banner').classList.add('fade-in');
	}
};
const Typist = (elem = null, options = {}) => {
	try {
		if (!elem) throw new Error('Typist error: elem undefined');

		const defaults = {
			text: elem.textContent,
			activeClass: 'is-active',
			speed: 200
		};

		const settings = merge({}, defaults, options);

		elem.classList.add(settings.activeClass);
		elem.textContent = '';

		const speedToDuration = () => {
			return (parseFloat(settings.speed) * settings.text.length) / 1000;
		};

		const tween = gsap.to(elem, {
			duration: speedToDuration(),
			text: settings.text,
			ease: 'none',
			onComplete: checkComplete
		});

		return tween;
	} catch (err) {
		console.error(err);
	}
};

export default Typist;
