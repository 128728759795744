if (document.querySelector('.contact-form')) {
	const form = document.querySelector('.contact-form');
	const formFields = form.querySelectorAll('input');
	const submitButton = form.querySelector('.submit-button');
	const requiredFields = [...formFields].filter(fields => fields.required);
	let validateFields = [];

	requiredFields.forEach(field => {
		field.addEventListener('change', function() {
			const parentField = this.closest('.form-field');
			
			if (this.value == '') {
				this.classList.add('error');
				parentField.classList.add('has-error');
			} else {
				const fieldFormat = this.classList.contains('contact-form__email') ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ : /^[A-Za-z .'-]+$/;
				
				if (this.value.match(fieldFormat)) {
					this.classList.remove('error');
					parentField.classList.remove('has-error');
				} else {
					this.classList.add('error');
					parentField.classList.add('has-error');
				}
			}

			validateFields = [...requiredFields].filter(fields => fields.value != '' && !fields.classList.contains('error'));
			
			if (validateFields.length == requiredFields.length) {
				submitButton.disabled = false;
			} else {
				submitButton.disabled = true;
			}
		});
	})
}

