'use strict';

import { merge } from 'lodash-es';
import gsap from 'gsap';

const Marquee = (elem = null, options = {}) => {
	try {
		if (!elem) throw new Error('Marquee error: elem undefined');

		const defaults = {
			bound: true,
			duration: 4,
			loop: false,
			direction: 'ltr',
			activeClass: 'is-active',
			contentClass: 'marquee__content'
		};

		const settings = merge({}, defaults, options);

		elem.classList.add(settings.activeClass);

		const content = elem.querySelector(`.${settings.contentClass}`);
		const contentWidth = content.offsetWidth;

		const tween = gsap.fromTo(content, {
			left: settings.bound ? '0' : `-${contentWidth}px`
		}, {
			left: settings.bound ? `calc(100% - ${contentWidth + 1}px)` : '100%',
			duration: settings.duration,
			ease: 'none',
			repeat: settings.loop ? -1 : 0,
			runBackwards: settings.direction === 'rtl',
			onReverseComplete: () => {
				if (settings.loop) tween.reverse(0);
			}
		});

		// public API
		return tween;
	} catch (err) {
		console.error(err);
	}
};

export default Marquee;
