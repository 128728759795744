'use strict';

import Carousel from './Carousel'

try {
	const defaults = {
		keyboard: false,
		peek: 500,
		breakpoints: {
			1800: {
				peek: 400
			},
			1500: {
				peek: 200
			},
			1200: {
				peek: 100
			},
			1000: {
				peek: 50
			},
			800: {
				peek: 20
			}
		}
	};

	const carousels = document.querySelectorAll('[data-module="HeroCarousel"]');
	[...carousels].forEach(carousel => {
		const settings = {
			...defaults
		};
		return new Carousel(carousel, settings);
	});
} catch (err) {
	console.log(err);
}
