'use strict';

import { merge } from 'lodash-es';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import TextPlugin from 'gsap/TextPlugin';
import Fader from '../Fader/Fader.js';
import Marquee from '../Marquee/Marquee.js';
import Shifter from '../Shifter/Shifter.js';
import Typist from '../Typist/Typist.js';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(TextPlugin);

const Animator = (elem = null, options = {}) => {
	try {
		if (!elem) throw new Error('Animator error: elem undefined');

		const defaults = {
			timelineConfig: (() => {
				try {
					const o = JSON.parse(elem.getAttribute('data-timeline-config'));
					if (o && typeof o === 'object') return o;
				} catch (err) {}
				return {};
			})(),
			tweens: (() => {
				try {
					const o = JSON.parse(elem.getAttribute('data-tweens'));
					if (o && typeof o === 'object') return o;
				} catch (err) {}
				return [];
			})()
		};

		const settings = merge({}, defaults, options);

		if (!Array.isArray(settings.tweens)) throw new Error('Animator error: tweens option must be an array');

		const timeline = gsap.timeline(settings.timelineConfig);

		const createTween = (pluginName, tweenOptions) => {
			switch (pluginName) {
				case 'Fader': return Fader(elem, tweenOptions);
				case 'Marquee': return Marquee(elem, tweenOptions);
				case 'Shifter': return Shifter(elem, tweenOptions);
				case 'Typist': return Typist(elem, tweenOptions);
				default: return gsap.to(elem, tweenOptions);
			}
		};

		settings.tweens.forEach((tween, i) => {
			const {
				plugin = '',
				insertionPoint = '<',
				...tweenOptions
			} = tween;

			timeline
				.add(createTween(plugin, tweenOptions), insertionPoint)
				.addLabel(`Tween-${i}`);
		});

		return timeline;
	} catch (err) {
		console.error(err);
	}
};

export default Animator;
