'use strict';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

if (document.querySelector('.homepage-banner') && window.innerWidth >= 768) {
	const html = document.querySelector('html');
	const windowWidth1 = document.documentElement.clientWidth;
	const windowWidth2 = window.innerWidth;
	const scrollWidth = windowWidth2 - windowWidth1;
	const homepageBanner = document.querySelector('.homepage-banner');
	const carouselWrapper = document.querySelector('.homepage-carousel-wrapper');
	const carouselHeader = document.querySelector('.homepage-carousel-header');
	const heroBanner = document.querySelector('.hero-banner');
	const heroTitle = document.querySelector('.hero-title');
	const mainImage = document.querySelector('.hero-main-image');
	const mainImageWidth = mainImage.offsetWidth;
	const mainImageHeight = mainImage.offsetHeight;
	const secondRow = heroBanner.querySelector('.second-row');
	const thirdRow = heroBanner.querySelector('.third-row');
	const backingImages = document.querySelector('.hero-backing-images');
	const glide = document.querySelector('.glide');
	const activeSlide = glide.querySelector('.glide__slide--active');
	const allGlideSlides = glide.querySelectorAll('.glide__slide');
	const glideSlides = [...allGlideSlides].filter(slide => !slide.classList.contains('glide__slide--active'));
	const offset = carouselWrapper.offsetHeight > window.innerHeight ? (carouselWrapper.offsetHeight - window.innerHeight) * .5 : 0;
	const triggerOptions = {
		trigger: homepageBanner,
		start: 'top top',
		end: () => '+=' + window.innerHeight,
		scrub: true,
		pin: true,
		fastScrollEnd: true,
		invalidateOnRefresh: true
	};
	const t1 = gsap.timeline({
		scrollTrigger: {
			...triggerOptions,
			once: true
		},
		onComplete: () => {
			homepageBanner.classList.remove('animating');
			glideSlides.forEach(slide => slide.style.opacity = 1);
		}
	});
	const t2 = gsap.timeline({
		scrollTrigger: {
			...triggerOptions
		}
	});

	window.onbeforeunload = () => {window.scrollTo(0, 0)};
	html.classList.add('lock-scroll');
	html.style.marginRight = `${scrollWidth}px`;
	homepageBanner.classList.add('animating');
	glideSlides.forEach(slide => slide.style.opacity = 0);

	setTimeout(() => {
		html.classList.remove('lock-scroll');
		html.removeAttribute('style');
	}, 3000);

	t1.to(heroTitle, {y: -100, opacity: 0})
	.fromTo(document.querySelector('.hero-subtitle'), {
		opacity: 1
	}, {
		y: -100,
		opacity: 0
	}, '<')
	.to(thirdRow, {width: '70%'})
	.to([thirdRow, secondRow], {width: '40%'})
	.call(() => {
		backingImages.classList.remove('hide');
		mainImage.querySelector('img').classList.remove('fade-full');
	})
	.to(mainImage, {height: '100vh', width: `${windowWidth1}px`}, '<')
	.call(() => {
		backingImages.classList.add('hide');
		mainImage.querySelector('img').classList.add('fade-full');
	})
	.to(mainImage, {width: `${windowWidth1 * .8}px`, delay: 1, duration: 1})
	.to(mainImage, {height: `${activeSlide.offsetHeight - 20}px`, width: `${((activeSlide.offsetWidth - 20) / window.innerWidth) * 100}%`, y: (carouselHeader.offsetHeight * .5) + 30 + offset, delay: 1, duration: 1})
	.fromTo(activeSlide, {
		opacity: 0
	}, {
		opacity: 1
	})
	.call(() => {
		heroBanner.classList.remove('hide');
		activeSlide.nextElementSibling.style.opacity = 0;
		activeSlide.previousElementSibling.style.opacity = 0;
	})
	.fromTo(carouselHeader, {
		y: '100%', opacity: 0
	}, {
		y: 0, opacity: 1
	})
	.call(() => heroBanner.classList.add('hide'))
	.call(() => {
		activeSlide.nextElementSibling.style.opacity = 1;
		activeSlide.previousElementSibling.style.opacity = 1;
	})
	.fromTo(activeSlide.nextElementSibling.querySelector('.card'), {
		x: '-105%'
	}, {
		x: 0
	})
	.fromTo(activeSlide.previousElementSibling.querySelector('.card'), {
		x: '105%'
	}, {
		x: 0
	}, '<')
	.fromTo(activeSlide.querySelector('.card__content'), {
		y: '100%'
	}, {
		y: '0%'
	})
	.call(() => homepageBanner.classList.add('animating'))
	.fromTo([activeSlide.nextElementSibling.querySelector('.card__content'), activeSlide.previousElementSibling.querySelector('.card__content')], {
		y: '100%'
	}, {
		y: '0%'
	});
}
