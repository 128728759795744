'use strict';

/* NOTE: This entire file is built for demo purposes only,
 * and should be removed or at least modified into a proper
 * init file prior to moving to production. The entire point
 * of this is to show how the modules can be initialized with
 * various settings applied
 */

import Typist from '../Typist/Typist.js';
import Marquee from '../Marquee/Marquee.js';
import Fader from '../Fader/Fader.js';
import Shifter from '../Shifter/Shifter.js';
import Animator from '../Animator/Animator.js';
import Director from '../Director/Director.js';


// -----------------------------------
// Demo Typists
// -----------------------------------
const typistsElems = [...document.querySelectorAll('[data-module="Typist"]')];
typistsElems.forEach(elem => {
	let options = {};
	if (elem.getAttribute('data-speed')) options.speed = elem.getAttribute('data-speed')

	const typist = new Typist(elem, options);

	window.typists = window.typists ? [...window.typists, typist] : [typist]; // store globally for demo
});

// -----------------------------------
// Demo Marquees
// -----------------------------------
const marqueeElems = [...document.querySelectorAll('[data-module="Marquee"]')];
marqueeElems.forEach(elem => {
	const isLooped = elem.getAttribute('data-loop');
	let options = {
		loop: isLooped,
		bound: !isLooped
	};
	if (elem.getAttribute('data-direction')) options.direction = elem.getAttribute('data-direction');
	if (elem.getAttribute('data-duration')) options.duration = elem.getAttribute('data-duration');

	const marquee = new Marquee(elem, options);

	window.marquees = window.marquees ? [...window.marquees, marquee] : [marquee]; // store globally for demo
});

// -----------------------------------
// Demo Faders
// -----------------------------------
const faderElems = [...document.querySelectorAll('[data-module="Fader"]')];
faderElems.forEach(elem => {
	let options = {};
	if (elem.getAttribute('data-duration')) options.duration = elem.getAttribute('data-duration');

	const fader = new Fader(elem, options);

	window.faders = window.faders ? [...window.faders, fader] : [fader]; // store globally for demo
});

// -----------------------------------
// Demo Shifters
// -----------------------------------
const shifterElems = [...document.querySelectorAll('[data-module="Shifter"]')];
shifterElems.forEach(elem => {
	let options = {ease: 'power1.inOut'};
	if (elem.getAttribute('data-duration')) options.duration = elem.getAttribute('data-duration');
	if (elem.getAttribute('data-start-x')) options.startX = elem.getAttribute('data-start-x');
	if (elem.getAttribute('data-start-y')) options.startY = elem.getAttribute('data-start-y');
	if (elem.getAttribute('data-end-x')) options.endX = elem.getAttribute('data-end-x');
	if (elem.getAttribute('data-end-y')) options.endY = elem.getAttribute('data-end-y');

	const shifter = new Shifter(elem, options);

	window.shifters = window.shifters ? [...window.shifters, shifter] : [shifter]; // store globally for demo
});

// ------------------------------------
// Demo Animators
// ------------------------------------
const animators = [...document.querySelectorAll('[data-module="Animator"]')];
animators.forEach(animator => {
	window.animators = window.animators ? [...window.animators, new Animator(animator)] : [new Animator(animator)]; // store globally for demo
});

// ------------------------------------
// Demo Directors
// ------------------------------------
const directors = [...document.querySelectorAll('[data-module="Director"]')];
directors.forEach(director => {
	window.directors = window.directors ? [...window.directors, Director(director)] : [Director(director)];
});
