'use strict';

import Carousel from './Carousel'

const caseStudyCarousel = document.querySelector('.case-study-carousel')

if (caseStudyCarousel) {
	const glide = caseStudyCarousel.querySelector('.glide')

	new Carousel(glide);
}